/* PolicyModal.css */
.policy-button {
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  position: relative;
  z-index: 1001;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content p {
  margin-bottom: 0;
  color: black; /* Asigură că textul este negru */
}

.close-modal-button {
  background-color: #3949ab;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.close-modal-button:hover {
  background-color: #1a237e;
}