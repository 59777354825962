/* Stil general */
.register-section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f3f4f6; /* Gri deschis */
  }
  
  .register-container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .register-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
  }
  
  .register-subtitle {
    font-size: 0.9rem;
    color: #666666;
    margin: 10px 0 20px 0;
  }
  
  .register-error {
    color: #e53e3e;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  .register-success {
    color: #43a047;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  .input-group {
    margin-bottom: 15px;
    position: relative;
  }
  
  .register-input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.9rem;
    background-color: #f9fafb;
    color: #333333;
    transition: border-color 0.3s ease;
  }
  
  .register-input:focus {
    border-color: #2563eb;
    outline: none;
  }
  
  .register-button {
    width: 100%;
    padding: 10px;
    background-color: #2563eb;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #1d4ed8;
  }
  
  .divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  
  .line {
    flex: 1;
    height: 1px;
    background-color: #ddd;
  }
  
  .divider-text {
    margin: 0 10px;
    font-size: 0.9rem;
    color: #666666;
  }
  
  .login-account {
    margin-top: 10px;
  }
  
  .login-account-text {
    font-size: 0.9rem;
    color: #666666;
  }
  
  .login-account-link {
    color: #2563eb;
    font-weight: bold;
    text-decoration: none;
  }
  
  .login-account-link:hover {
    text-decoration: underline;
  }

  .input-group {
    margin-bottom: 20px;
    position: relative;
  }
  
  .register-label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #333;
    text-align: left;
  }
  
  /* Responsivitate */
  @media (max-width: 768px) {
    .register-container {
      padding: 20px;
    }
  
    .register-title {
      font-size: 1.5rem;
    }
  
    .register-input {
      font-size: 0.8rem;
    }
  
    .register-button {
      font-size: 0.9rem;
    }
  }
  