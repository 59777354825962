/* Container general */
.abonamente-section {
    padding: 60px 0;
    text-align: center;
  }
  
  /* Titlul principal */
  .abonamente-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #283593;
    margin-bottom: 20px;
  }
  
  /* Buton */
  .btn-primary {
    display: inline-block;
    background-color: #6a1b9a;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #4a148c;
  }
  
  /* Listă punctată pentru facilități */
  ul li {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
  
  /* SVG pentru iconițe */
  ul li svg {
    margin-right: 8px;
  }
  
  /* Responsivitate */
  @media (max-width: 768px) {
    .lg\:grid-cols-3 {
      grid-template-columns: 1fr;
    }
  
    .btn-primary {
      width: 100%;
      padding: 12px;
    }
  }
  