.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.page-title {
  font-size: 2rem;
  color: #333;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.col-1-3 {
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
}

.col-2-3 {
  flex: 2 1 calc(66.666% - 20px);
  max-width: calc(66.666% - 20px);
}

.col-50 {
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
}

.section-title {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 10px;
}

.section-content {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: justify;
}

.section-content p {
  margin: 5px 0;
  color: #333;
}

.file-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
}

.file-icon {
  width: 24px;
  height: 24px;
}

.download-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.download-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .col-1-3,
  .col-2-3,
  .col-50 {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
