.search-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background-color: #e8eaf6;
  border-radius: 10px;
  margin: 20px 0;
  gap: 10px;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.search-input {
  flex-grow: 1;
}

.search-icon {
  margin-left: -30px;
  color: #555;
}

.filter-item {
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(25% - 10px);
}

.filter-item-full {
  display: flex;
  justify-content: center;
  flex: 1 1 100%;
  margin-top: 10px;
}

.filter-label {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #555;
}

.filter-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.date-range {
  display: flex;
  justify-content: space-between;
}

.date-input {
  width: 45%;
}

.search-button {
  padding: 10px 20px;
  background-color: #1e88e5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.search-button i {
  margin-right: 5px;
}

.alert {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
}

.expand-button {
  background-color: #1e88e5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
}

.search-expand-button {
  margin-top: 10px;
}

.advanced-search-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.advanced-search-title {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .filter-item {
    flex: 1 1 100%;
  }

  .filter-item-full {
    margin-top: 20px;
  }

  .date-input {
    width: 100%;
  }

  .expand-button {
    margin-bottom: 10px;
    width: 100%;
  }

  .advanced-search-toggle {
    flex-direction: column;
  }

  .advanced-search-title {
    margin-bottom: 10px;
  }
}
