/* Stil general pentru secțiuni */
.section {
  padding: 60px 0;
  text-align: center;
}

/* Background alb */
.white-bg {
  background-color: #ffffff;
}

/* Background gri */
.gray-bg {
  background-color: #f7f7f7;
}

/* Containerul general */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Stil pentru titlurile secțiunilor */
.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #283593;
}

/* Stil pentru text */
.section-text {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #555555;
  max-width: 800px;
  margin: 0 auto 20px auto;
}

/* Tabel stilizat */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.styled-table th, .styled-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.styled-table th {
  background-color: #283593;
  color: white;
  text-transform: uppercase;
}

.styled-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Butoane stilizate */
.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.styled-button {
  background-color: #283593;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.styled-button:hover {
  background-color: #1a237e;
}

/* Responsivitate */
@media (max-width: 768px) {
  .styled-button {
    width: 100%;
    padding: 10px;
  }

  .button-group {
    flex-direction: column;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-text {
    font-size: 1rem;
  }

  .styled-table th, .styled-table td {
    font-size: 0.875rem;
  }
}
