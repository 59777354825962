/* General Header */
.header {
  background-color: #283593;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
/* 
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
} */

/* Logo */
.logo-container {
  width: 20%;
}

.logo {
  height: 50px;
}

/* Menu */
.menu-container {
  width: 50%;
  justify-content: center;
  display: flex;
  gap: 15px;
}

.menu-link {
  font-size: 1rem;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.menu-link:hover {
  background-color: #3949ab;
}

/* Buttons */
.buttons-container {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.login-button {
  background-color: #3949ab;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.875rem;
  text-decoration: none;
}

.login-button:hover {
  background-color: #1a237e;
  /* Nu modificăm padding sau margin */
}

/* Dropdown */
.dropdown-container {
  position: relative;
}

.dropdown-button {
  background: none;
  color: white;
  font-size: 0.875rem;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 10px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.dropdown-item:hover {
  background-color: #f3f3f3;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.menu-icon {
  width: 24px;
  height: 24px;
  color: white;
}

/* Mobile Dropdown */
.mobile-menu {
  display: flex;
  flex-direction: column;
  background-color: #3949ab;
  padding: 10px;
}

.mobile-link {
  font-size: 1rem;
  color: white;
  text-decoration: none;
  padding: 10px 0;
}

/* Responsive */
@media (max-width: 768px) {
  .menu-container {
    display: none;
  }

  .hamburger {
    display: block;
  }
}
